<template>
  <div class="servicios" id="inicio" >
    <Menu2></Menu2>
    <Menu></Menu>
    <div class="banner-servicios mobile" >
      <img src="../Imagenes/servicios/servicios-mobile.png" alt="Servicios">
    </div>
    <div class="two">
      <div class="banner-servicios portrait">
        <img src="../Imagenes/servicios/servicios-escritorio.png" alt="Servicios">
      </div>
      <div class="servicios-content">
        <div class="servicios-content-title">
         <h4>Nuestros Servicios</h4>
        </div>
        <div v-for="(serv,index) in services" :key="index" class="ghost-card-service">
          <div :id="serv.id" :item="serv.id" ></div>
          <div class="card-service" >
            <div class="service-img" >
              <img :src="serv.image" alt="service">
            </div>
            <div class="service-content">
              <h6>{{serv.Service}}</h6>
              <p class="little-p">{{serv.description}}</p>
              <div class="btn-take-service"  href="#" @click.prevent="selectService(serv)">
                <a>Contactar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import services from '../../src/views/servicios.json'
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Servicios',
  components: {
    Menu,
    Menu2,
    Footer
  },
   data() {
    return {
      services: services,
      selectedService: {},
    }
   },
  methods: {
    selectService(serv) {
      this.selectedService= serv;
      let serviceSelect = this.selectedService.Service.trim().toLowerCase();
      if(this.$route.path !== '/form_service/') {
         window.location = `/form_service/?serviceSelect=${serviceSelect}`
      } else {
        this.$emit('interest-service', serviceSelect)
        this.serviceSelect = ''
      }
    },
  }
};
</script>

<style scoped>
.two{
  background-color: #f2f2f2;
  padding: 8rem 6rem;
  font-family: "Poppins";
}
.second-menu{
  padding-top: 5rem;
  position: fixed;
  width: 100%;
}

.banner-servicios img{
  width: 100%;
}
.servicios-content{
  margin: 3rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.servicios-content-title{
  width: 100%;
  text-align: left;
}
.servicios-content h4{
 color: #624fc7;
}

.card-service{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 2rem 0rem;
  border-radius: 20px;
  padding: 1.5rem;
  max-width: 750px;
}

.little-category{
  text-decoration: underline;
  font-size: 11px;
}
.service-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin: 0rem 2rem;
  text-align: left;
}
.service-content h6{
  font-size: 14px;
  font-weight: 800;
}
 .service-img img{
    width: 20rem;
    border-radius: 20px;
  }

.little-p{
  font-size: 12px;
  text-align: left;
}

.price{
  background-color: #10d5d5;
  color: #5a5bc8;
  font-weight: 600;
  font-size: 12px;
  width: 4rem;
  font-style: oblique;
  margin-bottom: 1rem;
}
.price p{
  margin:0rem;
}
.btn-take-service{
  border: 1.5px solid #4ff5dd;
  background-color:#4ff5dd ;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.btn-take-service a{
    color: #525bb7!important;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
}
.icon-up,.icon-down{
  width: 1rem;
  margin-right: 1rem;
}
@media (min-width: 992px){
  .mobile{
    display: none!important;
  }
  .card-service{
  flex-direction: row;
  }
}
@media (max-width: 991px){
  .banner-servicios img{
    margin-top: 5rem;
  }
  .servicios-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn-take-service{
    width: 100%;
  }
  .two{
    padding: 1rem;
    text-align: center;
  }
  .service-img img{
    width: 15rem;
    margin-bottom: 2rem;
  }
  .card-service{
    max-width: 300px;
  }
  .service-content{
    margin: 0rem;
  }
  .servicios-content-title{
    text-align: center;
  }
}

</style>